<template>
  <div class="color_page_bg all_page">
    <van-nav-bar :title="$t('message.customer_service')" left-arrow @click-left="$router.go(-1)" fixed/>
    <div class="service_content second_page_margin">
      <div class="item" v-for="(item,index) in cs_list" v-if="cs_list" style="margin-top: 50px;">
        <a :href="item.url" v-if="item.type != 3">
          <img src="@/assets/cs0.png" v-if="item.type == 0" />
          <img src="@/assets/cs1.png" v-if="item.type == 1" />
          <img src="@/assets/cs2.png" v-if="item.type == 2" />
          <img src="@/assets/cs3.png" v-if="item.type == 3" />
          <img src="@/assets/cs4.png" v-if="item.type == 4" />
          <div class="name" v-html="item.username"></div>
        </a>

        <a id="chat-btn" @click="call_livechat" v-if="item.type == 3">
          <img src="@/assets/cs3.png" v-if="item.type == 3" />
          <div class="name" v-html="item.username"></div>
        </a>

      </div>
    </div>
    <div class="service_tips">{{$t('message.contact_us_tips')}}</div>
    <div class="service_notice">
      <div class="title">{{$t('message.contact_us_tips_title')}}</div>
      <div class="content">
        <div v-html="$t('message.qd_desc_info_1') + ' ' + work_time_str"></div>
        <div >{{$t('message.qd_desc_info_2')}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { customerService } from '@/api/login.js';

export default {
  data() {
    return {
      cs_list:[],
      work_time_str:'',
      username:'',
      livechat_license:''
    }

  },
  name: 'RegisterNote',

  methods: {
    call_livechat(){
      LiveChatWidget.call("maximize");
    }
  },
  async created() {
    let that = this;
    const service_res = await customerService();
    if(service_res.code == 0){
      this.cs_list = service_res.cs_list;
      this.work_time_str = service_res.work_time_str;
      this.username = service_res.username;
      this.livechat_license = service_res.livechat_license;

      window.__lc = window.__lc || {};
      window.__lc.license = this.livechat_license;
      (function(n, t, c) {
        function i(n) {
          return e._h ? e._h.apply(null, n) : e._q.push(n)
        }
        var e = {
          _q: [],
          _h: null,
          _v: "2.0",
          on: function() {
            i(["on", c.call(arguments)])
          },
          once: function() {
            i(["once", c.call(arguments)])
          },
          off: function() {
            i(["off", c.call(arguments)])
          },
          get: function() {
            if (!e._h) throw new Error("[LiveChatWidget] You can't use getters before load.");
            return i(["get", c.call(arguments)])
          },
          call: function() {
            i(["call", c.call(arguments)])
          },
          init: function() {
            var n = t.createElement("script");
            n.async = !0,
              n.type = "text/javascript",
              n.src = "https://cdn.livechatinc.com/tracking.js",
              t.head.appendChild(n)
          }
        }; ! n.__lc.asyncInit && e.init(),
          n.LiveChatWidget = n.LiveChatWidget || e
      } (window, document, [].slice))

      LiveChatWidget.on("visibility_changed", function(data) {
        if (data.visibility === "maximized") {
          localStorage.setItem("livechat_chat_visible", true);
          LiveChatWidget.call("set_customer_name", that.username);
          // LiveChatWidget.call("set_customer_email", '');
        }
      });


    }
  },
  destroyed() {
  },
}

</script>

<style lang="less" scoped>
@import '@/css/pagestyle.less';

.service_content{
  .page_flex();
  .page_flex_row();
  .page_flex_center();
  .text_center();

  .item{
    padding: 20px;

    img{
      width: 60px;
    }

    .name{
      padding-top: 10px;
      // color: @primary_txt_color;
      font-size: 16px;
    }
  }
}

.service_tips{
  padding-top: 20px;
  .text_center();
}

.service_notice{
  padding: 10px;

  .title{
    margin-top: 40px;
    color: @primary_txt_color;
  }

  .content{
    padding-top: 10px;

    div{
      padding: 5px 0;
    }
  }
}
</style>
