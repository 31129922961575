<template>
  <div class="color_page_bg all_page">
    <van-nav-bar :title="$t('message.msg_title')" left-arrow @click-left="$router.go(-1)" fixed/>
    <div class="msg_content second_page_margin">
      <div class="title">{{mytitle}}</div>
      <div class="time">{{addtime | formatTimestamp}}</div>
      <div class="content" v-html="msg_content"></div>
    </div>

  </div>
</template>

<script>
import { msgContent } from '@/api/user';

export default {
  data() {
    return {
      mytitle: '',
      msg_content:'',
      addtime: '',
    }
  },
  name: 'MessageContent',

  methods: {
  },
  async created() {
    let id = this.$route.query.id;

    const msg_content = await msgContent(id);
    if(msg_content.code == 0){
      this.mytitle = msg_content.msg.title;
      this.msg_content = msg_content.msg.content;
      this.addtime = msg_content.msg.addtime;
    }
  },
  destroyed() {
  },
  filters: {
    towFloat(value) {
      let realVal = "";
      if (!isNaN(value) && value !== "") {
        realVal = parseFloat(value).toFixed(2);
      } else {
        realVal = "--";
      }
      return realVal;
    },
    formatTimestamp(timestamp){
      if (!timestamp) return '';
      const date = new Date(timestamp * 1000); // 假设传入的是秒级时间戳，如果是毫秒级则不乘以1000
      const options = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };
      return new Intl.DateTimeFormat('en-US', options).format(date);
    }
  },
}

</script>

<style lang="less" scoped>
@import '../../css/pagestyle.less';

.msg_content{
  padding: 10px;

  .title{
    .text_center();
    font-size: 20px;
    color: @primary_txt_color;
  }

  .time{
    font-size: 12px;
    padding: 10px 0;
  }

  .content{
    padding: 10px 0;
    font-size: 16px;
    color: @sub_txt_color;
    word-wrap: break-word;
  }
}
</style>
