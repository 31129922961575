<template>
  <div class="color_page_bg all_page">
    <van-nav-bar :title="$t('message.action_security_title')" left-arrow @click-left="$router.go(-1)" fixed/>

    <van-tabs class="second_page_margin" @click="onTabClick">
      <van-tab :title="$t('message.edit_pass')">
        <div class="security_block">
          <div class="security_input_block">
            <van-field type="password" class="up_radius" v-model="old_passwd_login" label="" :placeholder="$t('message.old_pass')">
            </van-field>

            <van-field type="password" v-model="new_passwd1_login" label="" :placeholder="$t('message.new_pass')">
            </van-field>

            <van-field type="password" class="down_radius" v-model="new_passwd2_login" label="" :placeholder="$t('message.qu_newpass')">
            </van-field>

            <div class="security_btn_block" style="margin-top: 100px;">
              <div class="security_btn btn_shape" style="width: 90%;" @click="change_login">
                {{$t('message.confirm_btn')}}
              </div>
            </div>
          </div>
        </div>
      </van-tab>
      <van-tab :title="$t('message.with_money_sub_title')">
        <div class="security_block">
          <div class="security_input_block">
            <van-field type="password" class="up_radius" v-model="old_passwd_wit" label="" :placeholder="$t('message.old_withdrawal_pass')">
            </van-field>

            <van-field type="password" v-model="new_passwd1_wit" label="" :placeholder="$t('message.new_withdrawal_pass')">
            </van-field>

            <van-field type="password" class="down_radius" v-model="new_passwd2_wit" label="" :placeholder="$t('message.qu_new_withdrawal_pass')">
            </van-field>

            <div class="security_btn_block" style="margin-top: 100px;">
              <div class="security_btn btn_shape" style="width: 90%;" @click="change_withdrawal">
                {{$t('message.confirm_btn')}}
              </div>
            </div>
          </div>
        </div>
      </van-tab>
    </van-tabs>


  </div>
</template>

<script>
import { editLoginPasswd,editWithdrawPasswd } from '@/api/user';

export default {
  data() {
    return {
      old_passwd_login: '',
      new_passwd1_login: '',
      new_passwd2_login: '',

      old_passwd_wit: '',
      new_passwd1_wit: '',
      new_passwd2_wit: '',
    }
  },
  name: 'Security',

  methods: {
    onTabClick(){

    },
    async change_login(){
      if(this.new_passwd1_login){
        if(this.new_passwd1_login == this.new_passwd2_login){
          const res = await editLoginPasswd(this.old_passwd_login,
          this.new_passwd1_login,this.new_passwd2_login);
          this.$toast(res.info);
          if(res.code == 0){
            setTimeout(function(){
              window.history.back();
            },1000);
          }
        }
        else{
          this.$toast(this.$t('message.login_passnot'));
        }
      }
      else{
        this.$toast(this.$t('message.please_newpass'));
      }
    },
    async change_withdrawal(){
      if(this.new_passwd1_wit){
        if(this.new_passwd1_wit == this.new_passwd2_wit){
          const res = await editWithdrawPasswd(this.old_passwd_wit,
          this.new_passwd1_wit,this.new_passwd2_wit);
          this.$toast(res.info);
          if(res.code == 0){
            setTimeout(function(){
              window.history.back();
            },1000);
          }
        }
        else{
          this.$toast(this.$t('message.login_passnot'));
        }
      }
      else{
        this.$toast(this.$t('message.please_newpass'));
      }
    }
  },
  filters: {
    towFloat(value) {
      let realVal = "";
      if (!isNaN(value) && value !== "") {
        realVal = parseFloat(value).toFixed(2);
      } else {
        realVal = "--";
      }
      return realVal;
    },
  },
  async created() {
  },
  destroyed() {
  },
}

</script>

<style lang="less" scoped>
@import '../../css/pagestyle.less';

.security_block{
  padding: 10px;

  .security_btn_block{
    padding: 10px 0;
    .page_flex();
    .page_flex_center();
  }

  .security_btn{
    color: @op_primary_txt_color;
    background: @primary_txt_color
  }
}
</style>
