<template>
  <div class="color_page_bg all_page">
    <van-nav-bar :title="$t('message.invite_tittle')" left-arrow @click-left="$router.go(-1)" fixed/>
    <div class="invite_block second_page_margin">
      <img src="@/assets/logo_l.png"/>
      <div class="content">
        <div class="tips">{{$t('message.my_tgcode')}}</div>

        <div class="code" v-html="invite_code"></div>
      </div>

      <div class="invite_btn_block" style="">
        <div class="danger_btn btn_shape" style="" @click="to_copy">{{$t('message.cp_my_tgcode')}}</div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      invite_code:'',
    }
  },
  name: 'Invite',

  methods: {
    to_copy(){
      let that = this;
      console.log('to cp..');
      navigator.clipboard.writeText(
        this.invite_code)
        .then(()=>{
          console.log('ok..');
          that.$toast(that.$t('message.copy_ok'));
        })
        .catch(
          (error)=>{
            console.log('error..');
          }
        )
    }
  },
  created() {
    this.invite_code = this.$route.query.code;
  },
  destroyed() {
  },
}

</script>

<style lang="less" scoped>
@import '../../css/pagestyle.less';

.invite_block{
  padding-top: 56px;
  .page_flex();
  .page_flex_column();
  .page_flex_center();
  .text_center();

  .content{
    padding: 10px;
    margin: 20px 10px;

    .tips{
      font-size: 16px;
      color: @primary_txt_color;
    }

    .code{
      margin-top: 20px;
      font-size: 16px;
      color: @high_primary_txt_color;
    }
  }

  img{
    margin: auto;
    background: @primary_bg;
    box-shadow: @shadow;
    border-radius: 10px;
    padding: 20px;
    width: 200px;
  }

  .invite_btn_block{
    margin-top: 10px;

    .page_flex();
    .page_flex_center();
    .text_center();
  }
}
</style>
