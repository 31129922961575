<template>
  <div class="color_page_bg all_page">
    <van-nav-bar :title="$t('message.msg_list_title')" left-arrow @click-left="$router.go(-1)" fixed/>
    <div class="msg_list_content second_page_margin">
      <div class="msg_list">
        <div class="item" v-for="(item,index) in msg_list" @click="to_msg_content(item.id)">
          <div class="title_read" v-if="item.rid">{{item.title}}</div>
          <div class="title_unread" v-else>{{item.title}}</div>
          <div class="time">{{item.addtime | formatTimestamp}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { msgList } from '@/api/user';

export default {
  data() {
    return {
      msg_list:[],
    }
  },
  name: 'MessageList',

  methods: {
    async getMsgList(){
      const res = await msgList();
      if(res.code == 0){
        this.msg_list = res.msg_list;
      }
    },
    to_msg_content(msg_id){
      let that = this;
      this.$router.push({
        path: '/message_content',
        query: {
          id: msg_id,
        }
      })
    }
  },
  created() {
    this.getMsgList();
  },

  destroyed() {
  },
  filters: {
    towFloat(value) {
      let realVal = "";
      if (!isNaN(value) && value !== "") {
        realVal = parseFloat(value).toFixed(2);
      } else {
        realVal = "--";
      }
      return realVal;
    },
    formatTimestamp(timestamp){
      if (!timestamp) return '';
      const date = new Date(timestamp * 1000); // 假设传入的是秒级时间戳，如果是毫秒级则不乘以1000
      const options = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };
      return new Intl.DateTimeFormat('en-US', options).format(date);
    }
  },
}

</script>

<style lang="less" scoped>
@import '../../css/pagestyle.less';

.msg_list{
  padding: 10px;

  .item{
    margin: 10px 0;
    padding: 10px;
    background: @white_bg;
    border-radius: 10px;

    .time{
      padding: 5px 10px;
    }

    .title_unread,
    .title_read{
      padding: 5px 10px;
    }

    .title_read{
      color: @sub_txt_color;
    }

    .title_unread{
      color: @msg_unread;
    }

  }
}


</style>
